/* Fonts  */
@font-face {
    font-family: hauora-medium;
    src: url(../../../../public/Hauora-Medium.ttf);
}

@font-face {
    font-family: hauora-semibold;
    src: url(../../../../public/Hauora-SemiBold.ttf);
}

.cards {
    font-family: hauora-medium;
}

.value-cards {
    width: 20rem;
    border: none;
    margin: 2vh auto;
}

.value-title {
    font-size: 2rem;
    font-family: hauora-medium;
    font-weight: 500;
}

.value-img {
    width: 100%;
    position: relative;
}

.value-circle {
    background-color: #fff;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 35px;
    z-index: 1;
}

.value-circle1 {
    position: absolute;
    right: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.value-icon {
    font-size: 1rem;
    color: #000;
    margin: 0;
}

.value-body {
    padding: 1rem;
    position: relative;
}

.value-text {
    font-size: 20px;
}

.value-title {
    font-size: 1.7rem;
}

/* Responsive media queries */
@media only screen and (max-width: 1863px) {
    .value-cards {
        width: 20rem;
    }

    .value-title {
        font-size: 25px;
    }

    .value-text {
        font-size: 1rem;
    }
}

@media only screen and (max-width: 1448px) {
    .value-cards {
        width: 16rem;
        height: 18rem;
    }

    .value-body {
        padding: 6px;
    }
}

@media only screen and (max-width: 1160px) {
    .value-cards {
        width: 13.5rem;
        height: 17.5rem;
    }

    .value-title {
        font-size: 21px;
    }
}

@media only screen and (max-width: 999px) {
    .value-cards {
        width: 20rem;
        height: 17.5rem;
    }

    .value-title {
        font-size: 21px;
    }
}


@media only screen and (min-width: 631px) and (max-width: 778px) {
    .value-cards {
        width: 17rem;
        height: 17.5rem;
    }
}

@media only screen and (min-width: 546px) and (max-width: 631px) {
    .value-cards {
        width: 15rem;
        height: 17.5rem;
    }
}

@media only screen and (max-width: 545px) {
    .value-cards {
        width: 100%;
        height: 100%;
    }

    .value-body {
        padding: 1rem;
    }

    .value-text {
        padding-right: 2rem;
    }
}