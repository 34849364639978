/* Font */
@font-face {
    font-family: hauora-medium;
    src: url(../../../../public/Hauora-Medium.ttf);
}

/* Cards Component Styles */
.cards {
    font-family: hauora-medium;
}

.cards-section {
    background-color: #F7F7F8;
    width: 30rem;
    height: 22rem;
    border: none;
    padding: 0vh 0px 1vh 0px;
    margin: 2vh auto;
}

.cards-section:hover {
    box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.10), 0px 8px 10px -6px rgba(16, 24, 40, 0.10);
}

.cards-heading {
    background-color: #3D3B6D;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 2vh 2vh 1vh 2vh;
}

.cards-head {
    font-size: 2rem;
    font-family: hauora-medium;
}

.cards-img {
    width: 46px;
    height: 32px;
    margin-top: 0.3vh;
}

.cards-img1 {
    width: 42px;
    height: 48px;
    margin-top: -0.6vh;
}

.cards-img2 {
    width: 50.998px;
    height: 45.846px;
    margin-top: -0.6vh;
}

.cards-section:hover .cards-heading .cards-img,
.cards-section:hover .cards-heading .cards-img1,
.cards-section:hover .cards-heading .cards-img2 {
    transform: scale(1.1);
}

.cards-text {
    padding: 1.2rem;
    font-size: 1.4rem;
}

.carousel-container {
    display: none;
}

/* Increase the size of the dots in CardsComponent */
.carousel-container .slick-dots li button:before {
    font-size: 11px !important;
    color: #000 !important;
}

/* Responsive media queries */
@media only screen and (min-width: 1920px) {
    .cards-section {
        width: 26rem;
        height: 20rem;
    }

    .cards-text {
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 1620px) {
    .cards-section {
        width: 22rem;
        height: 22rem;
    }

    .cards-text {
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 1200px) {
    .cards-section {
        width: 18rem;
        height: 20rem;
    }

    .cards-text {
        font-size: 1rem;
    }

    .cards-head {
        font-size: 1.5rem;
    }
}

@media only screen and (min-width: 632px) and (max-width: 800px) {
    .cards-section {
        width: 18rem !important;
        height: 20rem !important;
    }

    .cards-text {
        font-size: 1rem !important;
    }
}

@media only screen and (min-width: 545px) and (max-width: 800px) {
    .cards-section {
        width: 15rem;
        height: 18.2rem;
    }

    .cards-head {
        font-size: 1.5rem;
    }

    .cards-text {
        font-size: 0.85rem;
    }

    .cards-img {
        width: 40px;
        height: 30px;
    }

    .cards-img1 {
        width: 38px;
        height: 42px;
        margin-top: -0.6vh;
    }

    .cards-img2 {
        width: 48px;
        height: 42px;
        margin-top: -0.6vh;
    }
}

@media (max-width: 545px) {
    .card-container {
        display: none;
    }

    .carousel-container {
        display: block;
    }

    .cards-section {
        width: 90%;
        height: 90%;
        margin-left: 1rem;
    }
}

@media only screen and (max-width: 351px) {
    .cards-head {
        font-size: 1.4rem;
    }

    .cards-text {
        font-size: 1rem;
    }
}