/* Font  */
@font-face {
    font-family: hauora-medium;
    src: url(../../../../public/Hauora-Medium.ttf);
}

/* Animation for fading in elements */
.fade-in {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
}

.fade-in.visible {
    opacity: 1;
    transform: translateY(0);
}

/* Our Services Section */
.cards {
    font-family: hauora-medium;
    margin: 130px 4rem;
}

.section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 28rem;
    margin: 0 auto;
    padding-right: 5px;
}

.service-heading {
    margin: 0rem 0rem 1rem 0rem;
    font-size: 2rem;
    font-family: hauora-medium;
}

.service-text {
    font-size: 1.2rem;
    letter-spacing: 1px;
}

.hover-effect {
    width: 28rem;
    margin: 2vh auto;
    overflow: hidden;
    border: none;
    border-radius: 5px;
}

.hover-effect:hover .card-icon,
.hover-effect:hover .icon-circle {
    color: #fff;
    background-color: #B22335;
    font-size: 25px;
}

.card-img {
    transition: transform 0.3s ease-in-out;
    width: 100%;
    border-radius: 0px;
    height: 32vh;
}

.hover-effect:hover .card-img {
    transform: scale(1.1);
    overflow-x: hidden;
}

.card-body {
    background-color: rgb(239 239 239);
    z-index: 1;
    padding: 1.5rem;
    z-index: 0;
}

.card-title {
    font-family: hauora-medium;
    letter-spacing: 0.5px;
    font-size: 1.5rem;
}

.card-text {
    padding: 0rem 1rem;
    font-size: 20px;
}

.icon-circle {
    background-color: #fff;
    color: #B22335;
    width: 60px;
    height: 60px;
    position: absolute;
    bottom: 4%;
    right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.learnmore-btn {
    display: none;
}

.cards-small-view {
    display: none;
}

.icon-disable {
    cursor: pointer;
}

/* Responsive media queries */
@media only screen and (max-width: 1919px) {
    .cards-small-view {
        display: block;
    }

    .cards-large-view {
        display: none;
    }
}

@media only screen and (min-width: 1256px) and (max-width: 1500px) {
    .section {
        width: 24rem;
        height: 45vh;
    }

    .cards {
        margin: 130px 3rem;
    }

    .hover-effect {
        width: 24rem;
        height: 32rem;
    }
}

@media only screen and (max-width: 1256px) {
    .section {
        width: 22rem;
        height: 45vh;
    }

    .hover-effect {
        width: 22rem;
        height: 31rem;
    }

    .card-title {
        font-size: 25px;
    }

    .service-heading {
        font-size: 28px;
    }

    .service-text {
        font-size: large;
    }

    .cards {
        margin: 70px 2rem;
    }
}


@media only screen and (max-width: 1130px) {
    .section {
        width: 25rem;
    }

    .hover-effect {
        width: 26rem;
        height: 31rem;
    }
}

@media (max-width: 900px),
(min-width: 778px) and (max-width: 900px) {
    .section {
        width: 20rem;
    }

    .hover-effect {
        width: 21rem;
    }

    .card-body {
        padding: 0.5rem;
    }
}

@media only screen and (max-width: 735px) {
    .section {
        width: 18rem;
        height: 40vh;
    }

    .hover-effect {
        width: 19.5rem;
    }
}

@media (max-width: 778px),
(min-width: 675px) and (max-width: 778px) {
    .cards {
        margin: 70px 1rem;
    }

    .section {
        width: 16rem;
    }

    .hover-effect {
        width: 16rem;
    }

    .service-text,
    .card-text {
        font-size: 1rem;
        letter-spacing: 1px;
    }

    .card-text {
        padding: 0rem 1rem
    }

    .card-title {
        font-size: large;
    }

    .icon-circle {
        width: 45px;
        height: 45px;
    }
}

@media only screen and (max-width: 545px) {
    .section {
        width: 22rem;
        height: 20vh;
        margin-bottom: 2rem;
    }

    .hover-effect {
        width: 23rem;
        height: 28rem;
    }

    .icon-disable {
        display: none;
    }

    .card-text {
        padding: 0rem 0.8rem
    }

    .learnmore-btn {
        display: block;
        color: #B22335;
        padding: 1vh 0vh 0vh 0vh;
        margin: 0px;
    }
}

@media only screen and (max-width: 410px) {
    .section {
        width: 20rem;
        margin-bottom: 2rem;
    }

    .hover-effect {
        width: 20rem;
    }

    .card-body {
        padding: 10px;
    }
}


@media only screen and (max-width: 351px) {
    .cards {
        margin-left: 5px;
    }

    .section {
        height: 100%;
        width: 100%;
    }

    .hover-effect {
        width: 17.5rem;
    }
}