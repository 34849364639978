/* Define font face for 'hauora-medium' */
@font-face {
    font-family: hauora-medium;
    src: url(../../../public/Hauora-Medium.ttf);
}

/* Override the default accordion icon styles */
.accordion-button::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus-lg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2'/%3E%3C/svg%3E");
    transition: all 0.5s;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-x-lg' viewBox='0 0 16 16'%3E%3Cpath d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z'/%3E%3C/svg%3E");
}

/* Define styles for image container */
.image-container {
    position: relative;
}

/* Define styles for main image */
.about-main-image {
    width: 100%;
    height: 100%;
    margin-top: 3rem;
}

/* Add vertical lines on either side of the image container */
.image-container::before,
.image-container::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #ffffff;
}

.image-container::before {
    left: 32%;
}

.image-container::after {
    right: 34%;
}

/* Define styles for main about section */
.about-main,
.about-main1 {
    margin: 150px 6rem;
    font-family: hauora-medium;
}

/* Define styles for about us section */
.about-us {
    font-family: hauora-medium;
    font-size: large;
    letter-spacing: 1px;
}

/* Define styles for about page heading */
.about-page-heading,
.about-page-heading1 {
    font-size: calc(1.2rem + 1.3vw);
    font-family: hauora-medium;
    margin-bottom: 1rem;
}

.about-page-heading1 {
    margin-right: 5rem;
}

.about-page-text {
    font-size: 20px;
}

.work-image {
    width: 100%;
    height: 100%;
}

.work-text {
    margin: 3rem;
}

.work-text1 {
    margin: 0px 3rem;
}

.work-details {
    text-align: center;
    margin-top: 4rem;
}

.number-details {
    font-size: 45px;
    color: #B22335;
}

.work-experience {
    font-size: 22px;
    letter-spacing: 0.8px;
}

.accordion{
    --bs-accordion-border-radius: 0px;
    --bs-accordion-border-width: 0px;
}

.accordion-item {
    border-left: none;
    border-right: none;
    border-radius: 0px;
    border-top: 1px solid var(--Colors-Neutrals-600, #C1C3C7);
    border-bottom: 1px solid var(--Colors-Neutrals-600, #C1C3C7);
}

.accordion-button{
    font-size: 1.3rem;
    margin: 10px 0px;
    /* border-bottom: 1px solid var(--Colors-Neutrals-600, #C1C3C7); */
}

.accordion-button:not(.collapsed) {
    background-color: transparent;
}

.accordion-button:focus {
    box-shadow: none;
}

/* Responsive design for screens with max-width 1200px */
@media only screen and (max-width: 1200px) {
    .work-text {
        margin: 1rem;
    }

    .about-page-heading1 {
        margin-right: 0rem;
    }

    .work-text1 {
        margin: 1rem;
    }

    .about-main {
        margin: 150px 6rem 80px;
    }

    .about-main1 {
        margin: 80px 6rem;
    }
}

/* Responsive design for screens with max-width 1024px */
@media only screen and (max-width: 1024px) {
    .about-main {
        margin: 150px 2rem 50px;
    }

    .about-main1 {
        margin: 50px 2rem;
    }

    .work-text,
    .work-text1 {
        margin: 2rem 0rem 0rem 0rem;
    }

    .about-experience-text {
        flex-direction: column;
    }
}

/* Responsive design for screens with max-width 545px */
@media only screen and (max-width: 545px) {
    .about-main {
        margin: 100px 1rem 50px;
    }

    .about-main1 {
        margin: 50px 1rem;
    }

    .about-page-text {
        font-size: 16px;
    }

    .number-details {
        font-size: 25px;
    }

    .work-experience {
        font-size: 18px;
    }
}