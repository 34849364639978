@font-face {
    font-family: hauora-medium;
    src: url(../../../public/Hauora-Medium.ttf);
}

/* Header CSS */
.active,
.active-service,
.active-service>a,
.not-active:hover,
.not-active:hover>a {
    color: #B22335 !important;
    font-weight: 500;
}

.navbar-collapse {
    margin: 1rem;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.navbar-toggler:focus {
    box-shadow: none;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
    margin-top: 1.4rem;
    border-radius: 0px;
}

.dropdown-item,
.dropdown-item.active {
    font-size: 1.2rem;
    font-family: hauora-medium;
    padding: 0.8vh 0vh 0.8vh 2vh;
    background-color: transparent;
}

.dropdown-item:hover {
    color: #B22335;
    background-color: transparent;
}

.nav-link-with-margin {
    margin-right: 35px;
}

.not-active,
.not-active>a {
    color: #000;
}

.logo-section {
    width: 16rem;
}

.navbar {
    padding: 0rem 5rem;
    font-size: 1.1rem;
    background-color: #fff;
    border-bottom: 1px solid #A1A4AC;
}

.navbar-toggler {
    background-color: #fff;
}

.main-logo {
    font-size: 40px;
    margin-top: -5px;
}

.phone-icon {
    margin: -0.3vh 1vh 0vh 0vh;
}

.roof-quote-btn {
    background-color: #B22335;
    border-color: #B22335;
    border-radius: 0px;
    padding: 14px 18px;
    width: fit-content;
}

.roof-quote-btn:hover {
    background-color: #3D3B6D !important;
    border-color: #3D3B6D !important;
}

.roof-quote-btn:focus-visible {
    background-color: #3D3B6D !important;
    border-color: #3D3B6D !important;
    box-shadow: none;
}

.roof-canvas {
    width: 62% !important;
    top: 9.4% !important
}

.instant-heading {
    font-size: 2rem;
    text-align: center;
}

.instant-input-div {
    display: flex;
    justify-content: center
}

.instant-note-text {
    font-size: 15px;
    font-weight: 500;
    margin: 10px 0px 4vh;
    text-align: left;
}

.display-style1 {
    display: none;
}

.form-btn {
    border-radius: 0px;
    background-color: #B22335;
    border: 1px solid #B22335;
    font-size: 1.3rem;
    padding: 16px 32px 19px 32px;
    margin: 2px;
}

.form-btn:hover {
    background-color: #3D3B6D !important;
    border-color: #3D3B6D !important;
    box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.10), 0px 8px 10px -6px rgba(16, 24, 40, 0.10);
    padding: 18px 34px 21px 34px;
    margin: 0px;
}

.form-control.is-invalid, .was-validated .form-control:invalid{
    background-image: none;
}

/* Responsive media queries */
@media only screen and (max-width: 1550px) {
    .logo-section {
        width: 11.5rem;
    }

    .roof-quote-btn {
        font-size: 15px;
    }
}

@media only screen and (max-width: 991px) {
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        margin-top: 0rem;
        width: fit-content;
    }
}

@media only screen and (max-width: 1469px) {
    .nav-link-with-margin {
        margin-right: 15px;
    }

    .navbar {
        font-size: 15px;
    }
}

@media only screen and (max-width: 1256px) {
    .nav-link-with-margin {
        margin-right: 5px;
    }

    .navbar {
        padding: 0rem;
        font-size: 13px;
    }
}

@media only screen and (max-width: 1024px) {
    .roof-canvas {
        width: 72% !important;
    }
}

@media only screen and (max-width: 991px) {
    .navbar {
        padding: 10px;
        font-size: large;
    }

    .background-video-container {
        height: 80vh;
    }

    .main-heading {
        font-size: 2.6rem;
    }

    .phone-icon {
        margin: -0.4vh 1.5vh 0vh 0vh;
    }
}

@media only screen and (max-width: 650px) {
    .main-heading {
        font-size: 2rem !important;
    }

    .roof-canvas {
        width: 85% !important;
    }

    .instant-heading {
        font-size: 2rem
    }
}

@media only screen and (max-width: 450px) {
    .logo-section {
        width: 11.5rem;
    }

    .roof-canvas {
        width: 95% !important;
    }

    .instant-heading {
        font-size: 1.5rem
    }
}

@media only screen and (max-width: 400px) {
    .instant-input-div {
        display: block;
    }

    .display-style1 {
        display: block;
    }

    .display-style2 {
        display: none;
    }

    .instant-note-text {
        margin: 10px 0px 1vh;
    }
}