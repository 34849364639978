.gutter-service {
    background-image: url('../../assets/ServicesPagesImages/Gutters3.png');
    background-size: cover;
    background-color: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%)
}

.gutters-main-image {
    width: 100%;
    height: 100%;
    margin-top: 3rem;
}

.gutters-text2 {
    color: #fff;
    padding: 10vh 0px;
}

/* Responsive media queries */
@media only screen and (max-width: 1700px) {
    .gutters-image {
        width: 100%;
        height: 80%;
    }
}

@media only screen and (max-width: 900px) {
    .gutters-text {
        margin-top: 1rem;
    }
    .gutters-text2 {
        padding: 5vh 0px;
    }
    
}

@media only screen and (max-width: 575px) {
    .gutters-main-image{
        margin-top: 1.5rem;
    }
}